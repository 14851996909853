import Vue from 'vue';
import Router from 'vue-router';
import authGuard from '@/auth/auth-guard';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/stream-sources',
      name: 'stream-sources',
      component: () => import(/* webpackChunkName: "stream-sources" */ './views/stream-sources/stream-sources.vue')
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import(/* webpackChunkName: "settings" */ './views/settings/settings.vue')
    },
    {
      path: '/auth-callback',
      name: 'auth-callback',
      component: () => import('./views/auth/auth-callback.vue'),
      meta: {
        doesNotRequireAuth: true
      }
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      component: () => import('./views/auth/unauthorized.vue'),
      meta: {
        doesNotRequireAuth: true
      }
    },
    {
      path: '/forbidden',
      name: 'forbidden',
      component: () => import('./views/auth/forbidden.vue'),
      meta: {
        doesNotRequireAuth: true
      }
    },
    {
      path: '*',
      redirect: '/stream-sources'
    }
  ]
});

if (process.env.VUE_APP_AUTH0_DOMAIN !== 'not-set') {
  router.beforeEach(authGuard);
}

export default router;
