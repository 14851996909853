import axios from 'axios';
import Vue from 'vue';

const options = {
  baseURL: process.env.VUE_APP_API_URL,
  headers: { 'Content-Type': 'application/json', 'x-api-key': process.env.VUE_APP_API_KEY }
};

export default {
  settings: {
    delete(data) {
      return axios
        .delete('/api/settings/' + data.settingKey, options)
        .then(response => {
          Vue.$log.info('data received from api delete', response.data);
          return response.data;
        });
    },
    list() {
      return axios
        .get('/api/settings', options)
        .then(response => {
          Vue.$log.info('data received from api list', response.data);
          return response.data;
        });
    },
    save(data) {
      return axios
        .post('/api/settings', data, options)
        .then(response => {
          Vue.$log.info('data received from api save', response.data);
          return response.data;
        });
    },
    update(data) {
      return axios
        .put('/api/settings', data, options)
        .then(response => {
          Vue.$log.info('data received from api update', response.data);
          return response.data;
        });
    }
  },
  streamSources: {
    delete(data) {
      return axios
        .delete('/api/stream-sources/' + data.streamId, options)
        .then(response => {
          if (!response.data.branding.image) {
            response.data.branding.image = {
              isUrl: null,
              source: null,
              sourceForDisplay: null
            };
          }
          Vue.$log.info('data received from api delete', response.data);
          return response.data;
        });
    },
    list() {
      return axios
        .get('/api/stream-sources', options)
        .then(response => {
          response.data.map(item => {
            if (!item.branding.image) {
              item.branding.image = {
                isUrl: null,
                source: null,
                sourceForDisplay: null
              };
            }
          });
          Vue.$log.info('data received from api list', response.data);
          return response.data;
        });
    },
    save(data) {
      return axios
        .post('/api/stream-sources', data, options)
        .then(response => {
          if (!response.data.branding.image) {
            response.data.branding.image = {
              isUrl: null,
              source: null,
              sourceForDisplay: null
            };
          }
          Vue.$log.info('data received from api save', response.data);
          return response.data;
        });
    },
    update(data) {
      return axios
        .put('/api/stream-sources', data, options)
        .then(response => {
          if (!response.data.branding.image) {
            response.data.branding.image = {
              isUrl: null,
              source: null,
              sourceForDisplay: null
            };
          }
          Vue.$log.info('data received from api update', response.data);
          return response.data;
        });
    }
  }
};
