import AuthService from '@/auth/auth-service';

export default async (to, from, next) => {
  if (to.meta.doesNotRequireAuth) {
    return next();
  }

  const isAuthenticated = await AuthService.isAuthenticated();

  if (isAuthenticated) {
    const isAdmin = await AuthService.hasRole(process.env.VUE_APP_ADMIN_ROLES);
    if (!isAdmin) {
      return next({ name: 'forbidden' });
    }
    return next();
  }

  await AuthService.loginWithRedirect({
    appState: { targetUrl: to.fullPath }
  });
};
