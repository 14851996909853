import Vue from 'vue';
import Vuex from 'vuex';
import notifications from './modules/notifications/store-notifications';
import settings from './modules/settings/store-settings';
import streamSources from './modules/stream-sources/store-stream-sources';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    streamSources,
    settings,
    notifications
  },
  state: {
    packageVersion: process.env.PACKAGE_JSON_VERSION || '0.0.0'
  },
  getters: {
    appVersion: (state) => {
      return state.packageVersion;
    }
  }
});
