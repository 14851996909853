import { notificationsModuleActions } from './store-notifications-types';

const state = {
  items: []
};

// actions
const actions = {
  [notificationsModuleActions.success]({ commit }, message) {
    commit('pushNotification', { type: 'success', message });
  },
  [notificationsModuleActions.error]({ commit }, message) {
    commit('pushNotification', { type: 'error', message });
  },
  [notificationsModuleActions.info]({ commit }, message) {
    commit('pushNotification', { type: 'info', message });
  },
  [notificationsModuleActions.warning]({ commit }, message) {
    commit('pushNotification', { type: 'warning', message });
  },
  [notificationsModuleActions.audit]({ commit }, message) {
    commit('pushNotification', { type: 'audit', message });
  }
};

// mutations
const mutations = {
  pushNotification(statePassThru, data) {
    statePassThru.items.push({ ...data });
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
