import { getClient } from './auth-client';

export default {
  async loginWithRedirect(options) {
    const client = await getClient();
    return client.loginWithRedirect(options);
  },
  async handleRedirectCallback() {
    const client = await getClient();
    return client.handleRedirectCallback();
  },
  async isAuthenticated() {
    const client = await getClient();
    return client.isAuthenticated();
  },
  async getTokenSilently() {
    const client = await getClient();
    return client.getTokenSilently();
  },
  async hasRole(roles) {
    const client = await getClient();
    const userInfo = await client.getUser();
    return userInfo['https://ql.custom.openid.com/groups'].some(role => roles.includes(role));
  }
};
