import Vue from 'vue';
import Notifier from '@/components/notifier/notifier';

export default Vue.extend({
  components: { Notifier },
  props: {
    source: String
  },
  data: () => ({
    drawer: null
  }),
  created() {
    this.$vuetify.theme.dark = true;
  }
});
