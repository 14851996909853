import client from 'api-client';
import shortid from 'shortid';
import Vue from 'vue';
import { streamSourcesModuleActions } from './store-stream-sources-types';

const state = {
  items: []
};

// actions
const actions = {
  [streamSourcesModuleActions.delete]({ commit }, data) {
    return client.streamSources.delete(data)
      .then(res => commit('deleteStreamSource', res));
  },
  [streamSourcesModuleActions.list]({ commit }) {
    return client.streamSources.list()
      .then(res => commit('setStreamSources', res));
  },
  [streamSourcesModuleActions.create]({ commit }, data) {
    if (!data.streamId) {
      data.streamId = shortid();
    }
    return client.streamSources.save(data)
      .then(res => commit('addStreamSource', res));
  },
  [streamSourcesModuleActions.update]({ commit }, data) {
    return client.streamSources.update(data)
      .then(res => commit('updateStreamSource', res));
  }
};

// mutations
const mutations = {
  setStreamSources(statePassThru, data) {
    statePassThru.items = data;
  },
  addStreamSource(statePassThru, data) {
    statePassThru.items.push(data);
  },
  deleteStreamSource(statePassThru, data) {
    var index = statePassThru.items.findIndex(item => item.streamId === data.streamId);
    statePassThru.items.splice(index, 1);
  },
  updateStreamSource(statePassThru, data) {
    var index = statePassThru.items.findIndex(item => item.streamId === data.streamId);
    Vue.set(statePassThru.items, index, data);
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
