import Vue from 'vue';
import client from 'api-client';
import { settingsModuleActions } from './store-settings-types';

const state = {
  items: []
};

// actions
const actions = {
  [settingsModuleActions.delete]({ commit }, data) {
    return client.settings.delete(data)
      .then(res => commit('deleteSetting', res));
  },
  [settingsModuleActions.list]({ commit }) {
    return client.settings.list()
      .then(res => commit('setSettings', res));
  },
  [settingsModuleActions.create]({ commit }, data) {
    return client.settings.save(data)
      .then(res => commit('createSetting', res));
  },
  [settingsModuleActions.update]({ commit }, data) {
    return client.settings.update(data)
      .then(res => commit('updateSetting', res));
  }
};

// mutations
const mutations = {
  setSettings(statePassThru, data) {
    statePassThru.items = data;
  },
  createSetting(statePassThru, data) {
    statePassThru.items.push(data);
  },
  deleteSetting(statePassThru, data) {
    var index = statePassThru.items.findIndex(item => item.settingKey === data.settingKey);
    statePassThru.items.splice(index, 1);
  },
  updateSetting(statePassThru, data) {
    var index = statePassThru.items.findIndex(item => item.settingKey === data.settingKey);
    Vue.set(statePassThru.items, index, data);
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
